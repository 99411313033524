<template>
  <header>
    <div class="header__container">
      <div class="column-1">
        <h1 class="header__title">Movieclub</h1>
        <p class="header__p">Search whatever movie you want</p>
      </div>
      <div class="column-2">
        <img
          class="header__imgBig"
          src="../assets/img/clapperboard.png"
          alt=""
        />
        <img
          class="header__imgSmall"
          src="../assets/img/clapperboard.png"
          alt=""
        />
      </div>
    </div>
  </header>
</template>
